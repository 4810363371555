import React, { useState, useEffect, useCallback, useRef } from "react"
import { Layout, Menu, Breadcrumb, Modal, Input, Button, AutoComplete, Form, Typography, Divider } from "antd"
import { RootStateOrAny, useSelector, useDispatch } from "react-redux"
import useViewport from "../../../hooks/useViewport"
import NumPad from "react-numpad"
import { redeemLoyaltyPoints, applyRatioDiscount, removeDiscount } from "../../../redux/Checkout/slice"
import AlertModal from "../../common/AlertModal"
import { Customer, ScannnerFocusType } from "../../../models"
import { serveCustomer, appendCustomer } from "../../../redux/Customer/slice"
import ActionConfirmModal from "../../common/ActionConfirmModal"
import KeyboardModal from "../../common/KeyboardModal"
import { reloadCustomerScreen } from "../../../utils/customerscreen-functions"
import { syncModalToCustomerScreen } from "../../../utils/Global"
import { createNewCustomer } from "../../../api/customer"
import { customerInDBToCustomer } from "../../../utils/Global"
import { GlobalState, freshBarcode, freshWeight } from "../../../redux/Globals/slice"
import { searchCustomerOnline } from "../../../api/customer"
import NumpadModal from "../../common/NumpadModal"
import moment from "moment"

let buffer = '' // buffer for constructing the barcode from key presses

interface Props {
  originTotal: number
  isVisible: boolean
  setVisibility: (value: boolean) => void
  onGoing?: () => void
}

const CustomerInfoModal = ({ originTotal, isVisible, setVisibility, onGoing }: Props) => {
  const [isNumpadVisible, setIsNumpadVisible] = useState(false)
  // const [numpadValue, setNumpadValue] = useState("0")
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { height, width } = useViewport()
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { servingCustomer, customers } = Customer
  const Config = useSelector((state: RootStateOrAny) => state.Config)
  const { scannerFocus } = Config
  const Globals: GlobalState = useSelector((state: RootStateOrAny) => state.Globals)

  const [isAlertModalVisible, setIsAlertModalVisible] = useState<boolean>(false)
  const [isCustomerModalVisible, setIsCustomerModalVisible] = useState<boolean>(false)
  const [redeemPoints, setRedeemPoints] = useState(0)
  const [errorMsg, setErrorMsg] = useState("")
  const [searchCustomerValue, setSearchCustomerValue] = useState("")
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false)
  const [isInputKeyboardVisible, setIsInputKeyboardVisible] = useState(false)
  const [keyboardLanguage, setKeyboardLanguage] = useState("num")
  const [isCustomerJoinModalVisible, setIsCustomerJoinModalVisible] = useState(false)
  const [currentMode, setCurrentMode] = useState<"display" | "new">("display")
  const keyboardRef = useRef<any>()
  const [newCustomerInfo, setNewCustomerInfo] = useState<any>({ mode: "customerInfo" })
  const inputKeyboardRef = useRef<any>()
  const currentEditInputRef = useRef<string>()
  const currentEditInputValueRef = useRef<string>()

  const handleCustomerScan = (value: string) => {
    if (!value || value === '' || !isVisible) {
      console.log("扫客户码不满足条件")
      return
    }
    console.log("客户码扫到：", value)
    setSearchCustomerValue(value)
    // searchCustomer(value)
    //启动时间区间二倍积分
    const curDate: string = moment().format('YYYY-MM-DD')
    if (curDate >= '2024-02-09' && curDate <= '2024-02-11') {
      console.log("当前时间在范围内：", curDate)
      searchCustomer(value, true, true)
    } else {
      console.log("当前时间不在范围内：", curDate)
      searchCustomer(value, false, true)
    }

  }

  const scannerCallback = useCallback((event) => {
    let data = buffer || ''
    if (event.key === '') {
      return
    }
    if (event.key !== 'Enter') { // barcode ends with enter -key
      data += event.key
      buffer = data
    } else {
      buffer = ''
      if (isVisible && currentMode === "display") {
        handleCustomerScan(data)
      }
    }
  }, [isVisible, currentMode])
  //取消自动点数抵扣
  // useEffect(() => {
  //   //根据total得到可抵扣点数
  //   // console.log('servingCustomer是。。。。。。。', servingCustomer, serveCustomer)
  //   if (originTotal > 0 && servingCustomer) {
  //     const totalBy5: number = Math.max(Math.floor(originTotal / 5), 0)
  //     const redeemBy1000: number = Math.max(Math.floor(servingCustomer.Points / 1000), 0)
  //     // setRedeemPoints(Math.min(10, Math.min(totalBy5, redeemBy1000)) * 1000)//最多￥50
  //   }
  // }, [])
  useEffect(() => {
    servingCustomer && groupDiscount(servingCustomer)
  }, [])

  useEffect(() => {
    document.addEventListener('keypress', scannerCallback, true)
    return () => {
      document.removeEventListener('keypress', scannerCallback, true)
    }
  }, [isVisible, currentMode])

  useEffect(() => {
    if (Globals.barcode) {
      handleCustomerScan(Globals.barcode)
      dispatch(freshBarcode({
        barcode: ""
      }))
    }
  }, [Globals.barcode])

  // useEffect(() => {//清除keyboard残留数据
  //   if (!isKeyboardVisible) {
  //     clearKeyboard()
  //   }
  // }, [isKeyboardVisible])


  const searchCustomer = async (input: string, isScan?: boolean, byCode?: boolean) => {
    console.log("开始查询customer。。。", input)
    // const selectedCustomer = customers.filter((customer: Customer) => {
    //   return (
    //     customer.CustomerCode === input ||
    //     customer.Mobile === input ||
    //     customer.name.toLowerCase().indexOf(input.toLowerCase()) > -1)
    // })
    const result = await searchCustomerOnline(input, byCode)
    console.log('查看客户返回', result)
    const selectedCustomer = customerInDBToCustomer(result.data)
    console.log("selected customer: ", selectedCustomer)
    if (selectedCustomer.length >= 1) {//精确定位到该客户
      const setCustomerPromise = () => new Promise((resolve, reject) => {
        //isScan表示是否扫描customerApp客户码
        dispatch(serveCustomer({ customer: { ...selectedCustomer[0], isScan } }))
        syncModalToCustomerScreen(JSON.stringify(
          {
            mode: "customerInfo",
            customername: selectedCustomer[0].name,
            points: selectedCustomer[0].Points,
            mobile: selectedCustomer[0].Mobile,

          }
        ))
        resolve("finish")
      })
      setCustomerPromise()
        .then(() => {
          // reloadCustomerScreen()
        })
        .catch((e) => console.log(e))
      groupDiscount(selectedCustomer[0])//计算客户组折扣
    } else if (selectedCustomer.length > 1) {
      setErrorMsg("More than one customers found")
      setIsAlertModalVisible(true)
    } else {
      setErrorMsg("Sorry, we could not find the searched customer")
      setIsAlertModalVisible(true)
    }
  }
  const onUpdateInfo = (value: string) => {
    //修改输入的字段内容
    let valueObj = {}
    //@ts-ignore
    valueObj[currentEditInputRef.current] = value
    form.setFieldsValue(valueObj)
    setNewCustomerInfo({
      ...newCustomerInfo,
      ...valueObj
    })
    syncModalToCustomerScreen(JSON.stringify({
      ...newCustomerInfo,
      ...valueObj
    }))
  }
  const onSubmitCustomerForm = async (values: any) => {
    const customer = {
      CustomerName: values.customername,
      Mobile: values.mobile,
      Email: values.email,
      PostCode: values.postcode,
      Live: true
    }
    const result = await createNewCustomer(customer)
    if (result.CustomerCode) {
      const newCustomer = customerInDBToCustomer([result])[0]
      dispatch(appendCustomer({ customer: newCustomer }))
      dispatch(serveCustomer({ customer: newCustomer }))
      setCurrentMode("display")
      form.setFieldsValue({
        "customername": "",
        "email": "",
        "mobile": ""
      })
    } else {
      setErrorMsg("This Phone Number / Email is Already Been Registered!")
      setIsAlertModalVisible(true)
    }
    // console.log("result: ", result)

  }

  const groupDiscount = (servingCustomer: Customer) => {
    console.log('客户组折扣开始', servingCustomer)
    const discountValue = servingCustomer.Group?.DiscountValue
    if (discountValue && discountValue > 0) {
      console.log('客户组折扣执行1', discountValue)
      dispatch(applyRatioDiscount({ discount: discountValue }))
    } else {
      console.log('客户组折扣执行2', 0)
      dispatch(applyRatioDiscount({ discount: 0 }))
    }
  }

  const clearKeyboard = () => {
    keyboardRef?.current?.clear()
  }

  const onConfirmPoint = (value: number) => {
    const numberInput = Number(value)
    if (numberInput > servingCustomer.Points) {
      setErrorMsg("You Can Not Redeem Points Larger than Current Points!")
      setIsAlertModalVisible(true)
    } else if (!Number.isInteger(numberInput / 1000)) {
      setErrorMsg("You Can Only Redeem Points that are Multiplies of 1000!")
      setIsAlertModalVisible(true)
    } else {
      setRedeemPoints(numberInput)
      console.log("redeemLoyaltyPoints1")
      dispatch(redeemLoyaltyPoints({ points: numberInput }))
    }
    // form.setFieldsValue({ [editType]: value })
    setIsNumpadVisible(false)
    // onValuesChange()

  }

  return (
    <Modal
      className="modalPage"
      transitionName="none"
      maskTransitionName="none"
      title={<div className="modalTitle">
        Customer Info
        <div style={{ width: "40px", marginTop: "-10px" }}>
          <Divider className="modalDivider" />
        </div>
        <div className="normalLabel" style={{ color: 'red', marginBottom: '20px' }}>
          {servingCustomer && servingCustomer.Group && servingCustomer.Group.GroupName ? `The group discount is ONLY applied to NON-SPECIAL ltems` : ''}
        </div>
      </div>}
      maskStyle={{ opacity: 0.0 }}//遮罩透明
      visible={isVisible}
      footer={null}
      onCancel={(e) => {
        setVisibility(false)
        clearKeyboard()
        syncModalToCustomerScreen("closeCustomerModal")
        setNewCustomerInfo({ mode: "customerInfo" })
        // //Modal同步到客户屏
        //     //@ts-ignore
        //     if (window?.electronAPI) {
        //       //@ts-ignore
        //       window.electronAPI.modalVisibleSync("closeCustomerModal", (response) => {
        //         console.log('关闭modal同步显示在客户屏===成功: ', response);
        //       })
        //     }
      }}
      width={600}
    >
      <div style={{ marginTop: "-40px" }}>
        {isNumpadVisible ?
          <NumpadModal
            title={"RedeemPoints"}
            isVisible={isNumpadVisible}
            onConfirm={onConfirmPoint}
            onDismiss={() => {
              setIsNumpadVisible(false)
            }}
            value={redeemPoints.toFixed(0)}
            decimal={0}
            negative={false}
          />
          : null
        }
        <ActionConfirmModal
          isVisible={isConfirmModalVisible}
          description={"Confirm Reset Customer and Points to Redeem?"}
          actionText={"Confirm"}
          cancelText={"Cancel"}
          onConfirm={async () => {
            setIsConfirmModalVisible(false)
            setRedeemPoints(0)
            setSearchCustomerValue("")
            dispatch(serveCustomer({ customer: null }))
            dispatch(redeemLoyaltyPoints({ points: 0 }))
            dispatch(applyRatioDiscount({ discount: 0 }))//取消所有折扣
          }}
          onCancel={() => {
            setIsConfirmModalVisible(false)
          }}
          rightButtonDanger={true}
        />
        <AlertModal
          isVisible={isAlertModalVisible}
          description={errorMsg}
          buttonText={"OK"}
          onCancel={() => setIsAlertModalVisible(false)}
          onConfirm={() => setIsAlertModalVisible(false)}
        />
        <KeyboardModal
          ref={keyboardRef}
          isVisible={isKeyboardVisible}
          language={keyboardLanguage}
          onHideKeyboard={() => {
            setIsKeyboardVisible(false)
          }}
          onSwitchLanguage={() => {
            if (keyboardLanguage === "eng") {
              setKeyboardLanguage("kor")
            } else {
              setKeyboardLanguage("eng")
            }
          }}
          onConfirm={(value: string) => {
            console.log('keyboard modal confirm...', value)
            if (value && value !== '000001') {
              setSearchCustomerValue(value)
              searchCustomer(value)
            } else if (value === '000001') {
              setErrorMsg("You can not redeem points with Cash Customer!")
              setIsAlertModalVisible(true)
            }
            setIsKeyboardVisible(false)
          }}
          onSelect={(value) => {
            setSearchCustomerValue(value)
          }}
          initialValue={searchCustomerValue}
        />
        <KeyboardModal
          ref={inputKeyboardRef}
          isVisible={isInputKeyboardVisible}
          language={keyboardLanguage}
          onHideKeyboard={() => {
            setIsInputKeyboardVisible(false)
          }}
          onSwitchLanguage={() => {
            if (keyboardLanguage === "eng") {
              setKeyboardLanguage("kor")
            } else {
              setKeyboardLanguage("eng")
            }
          }}
          onConfirm={(value: string) => {
            console.log('inputkeyboard modal confirm...', value)
            // onUpdateInfo(value)
            setIsInputKeyboardVisible(false)
          }}
          onSelect={(value) => {
            onUpdateInfo(value)
          }}
          initialValue={currentEditInputValueRef.current}
        />
        <div style={{ display: "flex", flexDirection: "column", zIndex: 100, }}>
          {
            servingCustomer ?
              <div style={{}}>
                <div style={{ padding: 10, display: "flex", flexDirection: "column", backgroundColor: "#EEEEEE", borderRadius: 8, marginBottom: 10 }}>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="normalLabel" style={{ width: "200px" }}>Name: </div>
                      <div className="normalValue" style={{ fontWeight: "normal" }}>{servingCustomer.name}{servingCustomer.Group && servingCustomer.Group.GroupName ? `/${servingCustomer.Group.GroupName}:${servingCustomer.Group.DiscountValue}%${servingCustomer.Group.IfGetPoints ? 'with points' : ''}` : ''}</div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="normalLabel" style={{ width: "150px" }}>Points: </div>
                      <div className="normalValue">{servingCustomer.Points}</div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="normalLabel" style={{ width: "250px" }}>Mobile: </div>
                      <div className="normalValue">{servingCustomer.Mobile ? servingCustomer.Mobile : "Unknown Number"}</div>
                    </div>


                  </div>

                  <div style={{ marginTop: "15px", display: "flex", flexDirection: "column" }}>
                    <div className="normalLabel" >
                      Not the Current Customer? Please Re-Scan
                    </div>

                    <Input
                      value={searchCustomerValue}
                      onChange={(e) => {
                        setSearchCustomerValue(e.target.value)
                      }}
                      onClick={() => {
                        setIsKeyboardVisible(true)
                      }}
                      style={{ width: "100%", fontSize: 20, height: 40, marginTop: "10px" }}
                      placeholder={"Please Scan Customer Code"}
                    />

                  </div>

                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginTop: 15, }}>
                    {/* <NumPad.Number
                      // className="numpadNumber"
                      onChange={(value: string) => {
                        const numberInput = Number(value)
                        if (numberInput > servingCustomer.Points) {
                          setErrorMsg("You Can Not Redeem Points Larger than Current Points!")
                          setIsAlertModalVisible(true)
                        } else if (!Number.isInteger(numberInput / 1000)) {
                          setErrorMsg("You Can Only Redeem Points that are Multiplies of 1000!")
                          setIsAlertModalVisible(true)
                        } else {
                          setRedeemPoints(numberInput)
                          dispatch(redeemLoyaltyPoints({ points: numberInput }))
                        }
                      }}
                      value={redeemPoints}
                      decimal={0}
                      negative={false}
                    > */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="normalLabel">Redeem Points (1000pts=$5):</div>
                      <Input value={redeemPoints} style={{ width: 240, fontSize: 20, height: 40, marginTop: "10px" }} onClick={() => setIsNumpadVisible(true)} />
                    </div>
                    {/* </NumPad.Number> */}
                  </div>
                  <div style={{ marginTop: 30, display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div className="normalLabel">
                      You can save:
                    </div>
                    <div className="normalValue" style={{ marginLeft: "20px" }}>
                      {/* [ MIN(originTotal({originTotal})/5={Math.floor(originTotal / 5)}, Points({redeemPoints})/1000={Math.floor(redeemPoints / 1000)}, $50/5)=
                    {Math.min(10, Math.min(Math.floor(originTotal / 5), Math.floor(redeemPoints / 1000)))} ] * $5 =  */}
                      ${Math.min(10, Math.min(Math.floor(originTotal / 5), Math.floor(servingCustomer.Points / 1000))) * 5}
                    </div>

                  </div>
                </div>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                  <Button
                    onClick={() => {
                      setIsConfirmModalVisible(true)
                    }}
                    style={{ width: 200, height: 50, fontSize: 22, backgroundColor: "#E63737", borderRadius: 5, color: "#fff" }}
                  >
                    <img src={process.env.PUBLIC_URL + "/images/assets/icons/reset-icon.png"} className="pageIcon" />
                    Reset
                  </Button>
                  <Button
                    // type="primary"
                    onClick={() => {
                      console.log("三步skip")
                      console.log("redeemLoyaltyPoints2")
                      // dispatch(redeemLoyaltyPoints({ points: redeemPoints }))
                      setVisibility(false)
                      syncModalToCustomerScreen("closeCustomerModal")
                      // servingCustomer && groupDiscount(servingCustomer)
                      onGoing && onGoing()
                      // setIsCustomerModalVisible(false)
                    }}
                    style={{ width: 200, height: 50, fontSize: 22, backgroundColor: "#E3ECFF", borderRadius: 5, color: "#2C6EF1" }}
                  >
                    {currentMode === 'display' && !servingCustomer ? 'Skip' : 'Continue'}
                  </Button>
                </div>
              </div>
              :
              currentMode === "new" ?
                <div >
                  <Form
                    name="basic"
                    // labelCol={{ span: 8 }}
                    // wrapperCol={{ span: 16 }}
                    initialValues={{}}
                    onFinish={onSubmitCustomerForm}
                    onSubmitCapture={(e) => e.preventDefault()}
                    form={form}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <button
                      className="staffOperateButton"
                      style={{ backgroundColor: "#2C6EF1", flexDirection: "row", width: "100%", height: "40px" }}
                      onClick={() => {
                        setCurrentMode("display")
                      }}
                    >

                      <img src={process.env.PUBLIC_URL + "/images/assets/icons/scanCustomer-icon.png"} className="pageIcon" style={{ width: 24, height: 20 }} />
                      Switch to Customer Scan
                    </button>
                    <div style={{ color: "#2C6EF1", marginTop: 25 }}>New Customer Join</div>
                    <div className="newCustomerContainer" style={{ padding: 10, backgroundColor: "#EEEEEE", borderRadius: 8 }}>
                      <div className="newCustomerContainerL2C1">
                        <a style={{ color: "red" }}>*</a>PostCode
                        <Form.Item
                          // label="Email"
                          name="postcode"
                          rules={[{ required: true, message: 'Please input postcode!' }]}
                        >
                          <Input style={{ width: "100%", height: "40px" }}
                            onClick={(e) => {
                              // console.log('查看当前事件值', e)
                              currentEditInputRef.current = "postcode"
                              //@ts-ignore
                              currentEditInputValueRef.current = e.target.value
                              // setCurrentInput(e.target.value)
                              setIsInputKeyboardVisible(true)
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="newCustomerContainerL2C2">
                        Email
                        <Form.Item
                          // label="Email"
                          name="email"
                          rules={[{ required: false, message: 'Please input email!' }]}
                        >
                          <Input style={{ width: "100%", height: "40px" }}
                            onClick={(e) => {
                              //@ts-ignore
                              currentEditInputValueRef.current = e.target.value
                              currentEditInputRef.current = "email"
                              setIsInputKeyboardVisible(true)
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="newCustomerContainerL1C1">
                        <a style={{ color: "red" }}>*</a> Customer Name
                        <Form.Item
                          name="customername"
                          rules={[{ required: true, message: 'Please input customer name!' }]}
                        >
                          <Input style={{ width: "100%", height: "40px" }}
                            onClick={(e) => {
                              currentEditInputRef.current = "customername"
                              //@ts-ignore
                              currentEditInputValueRef.current = e.target.value
                              setIsInputKeyboardVisible(true)
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="newCustomerContainerL1C2" >
                        <a style={{ color: "red" }}>*</a> Mobile
                        <Form.Item
                          name="mobile"
                          rules={[{ required: true, message: 'Please input valid mobile number!', pattern: new RegExp(/^[0-9]{10,10}$/) }]}
                        >
                          <Input style={{ width: "100%", height: "40px" }}
                            onClick={(e) => {
                              //@ts-ignore
                              currentEditInputValueRef.current = e.target.value
                              currentEditInputRef.current = "mobile"
                              setIsInputKeyboardVisible(true)
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div style={{ padding: 10, display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                      <button
                        className="staffOperateButton"
                        style={{ backgroundColor: "#E3ECFF", flexDirection: "row", width: "49%", height: "40px", color: "#2C6Ef1" }}
                        onClick={() => {
                          // dispatch(redeemLoyaltyPoints({ points: redeemPoints }))
                          console.log("一步skip")
                          setVisibility(false)
                          syncModalToCustomerScreen("closeCustomerModal")
                          // setIsCustomerModalVisible(false)
                        }}
                      >
                        Skip
                      </button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: "49%", height: "40px", backgroundColor: "#2C6EF1", borderRadius: "5px" }}
                      >
                        Join
                      </Button>
                    </div>
                  </Form>
                </div>
                :
                <div>
                  <div style={{ padding: 10, display: "flex", flexDirection: "column", backgroundColor: "#EEEEEE", borderRadius: 8, marginBottom: 10 }}>
                    <div style={{ display: "flex", flexDirection: "row", marginBottom: 20 }}>
                      <Typography style={{ fontWeight: 300, fontSize: 22, marginRight: 20, alignSelf: "center", fontFamily: "DINPro", color: "#2C6EF1", width: "350px" }}>
                        Scan Existing Customer / Join as New Customer
                      </Typography>
                      <button
                        className="staffOperateButton"
                        style={{ backgroundColor: "#2C6EF1", flexDirection: "row", width: "198px" }}
                        onClick={() => {
                          setCurrentMode("new")
                        }}
                      >
                        <img src={process.env.PUBLIC_URL + "/images/assets/icons/addCustomer-icon.png"} className="pageIcon" style={{ width: 24, height: 20 }} />
                        <div style={{ width: 127, textAlign: "left" }}>New Customer Join</div>
                      </button>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Input
                        value={searchCustomerValue}
                        onChange={(e) => {
                          setSearchCustomerValue(e.target.value)
                        }}
                        onClick={() => {
                          setIsKeyboardVisible(true)
                        }}
                        style={{ width: "100%", fontSize: 20, height: 80 }}
                        placeholder={"Please Scan Membership Card or Input Customer Mobile"}
                      />
                    </div>
                  </div>
                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <Button
                      onClick={() => {
                        setVisibility(false)
                        syncModalToCustomerScreen("closeCustomerModal")
                      }}
                      style={{ width: 200, height: 50, fontSize: 22, backgroundColor: "#E3ECFF", borderRadius: 5, color: "#2C6EF1" }}
                    >
                      Back
                    </Button>
                    <Button
                      // type="primary"
                      onClick={() => {
                        console.log("二步skip")
                        console.log("redeemLoyaltyPoints3")
                        // dispatch(redeemLoyaltyPoints({ points: redeemPoints }))
                        setVisibility(false)
                        syncModalToCustomerScreen("closeCustomerModal")
                        // servingCustomer && groupDiscount(servingCustomer)
                        onGoing && onGoing()
                      }}
                      style={{ width: 200, height: 50, fontSize: 22, backgroundColor: "#E3ECFF", borderRadius: 5, color: "#2C6EF1" }}
                    >
                      {currentMode === 'display' && !servingCustomer ? 'Skip' : 'Continue'}
                    </Button>
                  </div>
                </div>
          }
        </div>

      </div>
    </Modal>
  )
}

export default CustomerInfoModal