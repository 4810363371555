import moment from "moment"
import React, { useEffect, useState, useCallback, useRef } from "react"
import {
  Layout,
  Menu,
  Breadcrumb,
  Modal,
  List,
  Radio,
  Input,
  Space,
  Typography,
  Button,
  Divider,
  notification,
  Image,
  Form,
  Table,
  message,
  Tag,
} from "antd"
import { CreditCardFilled, DollarCircleFilled } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import useViewport from "../../hooks/useViewport"
import { primary } from "../../constants/Colors"
import ProgressModal from "../common/ProgressModal"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import {
  calculatePaymentRounding,
  cartItemsToInvoiceItems,
  randomInteger,
  cartItemsToProductCodeOnlyItems,
  transferPaymentMethod,
  generateImagePath,
} from "../../utils/Global"
import ActionConfirmModal from "../common/ActionConfirmModal"
import { InvoiceHead, InvoiceItem, RecvAcct, Total } from "../../models"
import { BackendPaymentMethod } from "../../constants/Enums"
import { postInvoice } from "../../api/invoice"
import { addInvoice } from "../../redux/Customer/slice"
import AlertModal from "../common/AlertModal"
import { setToken } from "../../redux/Config/slice"
import { postRefund } from "../../api/refund"
import RefundInfoModal from "./RefundInfoModal"
import { clearRefundCart, setRefundMode } from "../../redux/Checkout/slice"
import RefundReceiptModal from "./RefundReceiptModal"
import { doPurchase, getMessage, getReceipt } from "../../api/payment"
import { dateFormat } from "../../constants/data"
import { getConfiguration } from "../../utils/configurate"
import { GlobalState, freshBarcode, freshWeight } from "../../redux/Globals/slice"
import { getWarehouseName } from "../../api/warehouse"
import { royalpayRefundOrder, checkRefundStatus } from "../../utils/royalpay-functions"
import { loginUser } from "../../api/user"
import { setReceiptCopy } from "../../redux/Payment/slice"
import KeyboardModal from "../common/KeyboardModal"

const logo_royalpay = require("../../assets/png/logo_royalpay.png")
let buffer = "" // buffer for constructing the barcode from key presses

interface Props {
  isVisible: boolean
  setVisibility: (value: boolean) => void
  onRefundSuccess: (invoice: any) => void
  selectedPaymentType: BackendPaymentMethod
}


export default function ManagerApproveModal({ isVisible, setVisibility, onRefundSuccess, selectedPaymentType }: Props) {
  const { height, width } = useViewport()
  const Payment = useSelector((state: RootStateOrAny) => state.Payment)
  const {
    paymentTotal,
    currentInvoice,
    showReceiptModal,
    paymentType,
    paymentMethod1,
    paymentMethod2,
    paymentAmount1,
    paymentAmount2,
  } = Payment
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { total, itemsInCart, isRefundMode, currentRefundInvoice, refundItems, refundTotal } = Checkout
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const CurUser = useSelector((state: RootStateOrAny) => state.User)
  const { servingCustomer } = Customer
  const Globals: GlobalState = useSelector((state: RootStateOrAny) => state.Globals)

  const [invoiceShopName, setInvoiceShopName] = useState("")
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isScanMode, setIsScanMode] = useState(true)
  // const [isScanMode, setIsScanMode] = useState(false)
  const [isInProgress, setIsInProgress] = useState<boolean>(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false)
  const [isAlertModalVisible, setIsAlertModalVisible] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [selectedRefundMethod, setSelectedRefundMethod] = useState<BackendPaymentMethod | null>(selectedPaymentType)
  const PosConfig = getConfiguration("ManagerApproveModal")
  const { machineID: MachineID, warehouseCode: WarehouseCode } = PosConfig
  const [curParams, setCurParams] = useState<any>()
  const [responseMsg, setResponseMsg] = useState<any>({ type: undefined, message: undefined })
  const isSubmitting = useRef<boolean>(false)

  const keyboardRef = useRef<any>()
  const inputRef = useRef<any>()
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false)
  const [keyboardLanguage, setKeyboardLanguage] = useState("eng")
  const [form] = Form.useForm()

  let IntervalMessage: any
  let IntervalCount: number = 0
  const dispatch = useDispatch()
  const navigate = useNavigate()

  let customerCopySaved: boolean = false

  const itemColumns = [
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
      render: (text: string, record: any, index: number) => String(index + 1).padStart(2, "0"),
    },
    {
      title: "Image",
      dataIndex: ["Product", "ImageLoadPath"],
      key: "ID",
      render: (image: string, record: InvoiceItem) => (
        <Image src={generateImagePath(image)} width={50} />
      ),
    },
    {
      title: "Description",
      dataIndex: ["Product", "Description"],
      key: "ID",
      render: (description: string, record: InvoiceItem) => (
        <Typography>{description}</Typography>
      ),
    },
    {
      title: "Qty",
      dataIndex: "planRefundQty",
      key: "ID",
      render: (text: number, record: InvoiceItem) => (
        <Typography>{text}</Typography>
      ),
    },
    {
      title: "UnitPrice",
      dataIndex: "Price",
      // dataIndex: "UnitCost",
      key: "ID",
      render: (text: any) => <Typography>${text.toFixed(2)}</Typography>,
    },
    {
      title: "Refund Subtotal",
      dataIndex: "refundTotal",
      render: (text: any) => <Typography>-{text?.toFixed(2)}</Typography>,
    },
    // {
    //   title: "GST",
    //   dataIndex: "GSTRate",
    //   key: "ID",
    //   render: (text:any) => <Typography>{text?.toFixed(0)}%</Typography>
    // },
  ]

  const getCurrentInvoicePayBy = () => {
    let result = "NA"
    if (currentRefundInvoice?.RecvAccts?.length > 0) {
      result = currentRefundInvoice.RecvAccts[0].PayBy
    }
    return result
  }

  const getRefundInvoiceItems = () => {
    const result: InvoiceItem[] = []
    refundItems.forEach((item: any) => {
      const newItem = {
        // Price: item.refundTotal,
        ProductCode: item.ProductCode,
        Qty: -item.planRefundQty,
        // UnitCost: item.UnitCost,
        Price: item.Price,
        Discount: item.Discount,
        GSTRate: item.GSTRate,
        ReferenceItemID: item.ID,
      }
      //@ts-ignore
      result.push(newItem)
    })
    return result
  }

  const postRefundCallback = (response: any) => {
    const invoice = response.data
    onRefundSuccess(invoice)
    setVisibility(false)
  }

  const callRoyalPayApi = async () => {
    //1.获得订单的royalpay记录
    const royalRecv: RecvAcct[] = currentRefundInvoice.RecvAccts.filter((acct: RecvAcct) => !acct.Notes && acct.PayBy === BackendPaymentMethod.RoyalPay)
    if (royalRecv.length === 0) {
      message.error("The original order was not paied by RoyalPay.")
      return
    }
    let royalResponse
    if (royalRecv[0].RoyalPay) {
      royalResponse = JSON.parse(royalRecv[0].RoyalPay)
    } else {
      message.error("RoyalPay record is not exist.")
      return
    }

    console.log("解析的Royalpay Response是。。。", royalResponse)

    if ((refundTotal.totalAmount * 100) > royalResponse.real_fee) {//单个订单退款单金额总和不能超过用户支付金额
      message.error("Total amount of all refund orders must be less than actual paid amount.")
      return
    }
    const params = {
      partnerOrderId: royalResponse.partner_order_id,
      partnerRefundId: randomInteger(300000, 400000),
      // price: Number(total.totalAmount?.toFixed(2)) * 100
      fee: refundTotal.totalAmount * 100,
      deviceId: MachineID || "POS99",
    }
    const response = await royalpayRefundOrder(params)
    console.log("royalpayRefundOrder response: ", response)
    if (response.status === 200) {//提交成功，监控退款执行
      if (response.data.return_code !== "SUCCESS" && response.data.return_code !== "FINISHED") {
        message.error(response.data.return_code + "::" + response.data.message)
        setResponseMsg({
          type: "failed",
          message: "RoyalPay refund failed. Please try again or change the payment method.",
        })
        return
      }
      message.success(response.data.return_code + "::" + response.data.message)
      setResponseMsg({
        type: "success",
        message: "RoyalPay refund approved. Please waiting for return to customer......",
      })
      //todo 是否要在线跟踪？需要做一单看看退款是否实时，还是需要审批
      IntervalMessage = setInterval(async () => {
        IntervalCount += 1
        if (IntervalCount > 30) {
          clearInterval(IntervalMessage)
          setResponseMsg({ type: "failed", message: "Time Out" })
          return
        }
        const res = await checkRefundStatus(params)
        if (response.status === 200 && (response.data.result_code === "SUCCESS" || response.data.result_code === "FINISHED")) {
          setResponseMsg({ type: "success", message: "RoyalPay Refund Successed." })
          clearInterval(IntervalMessage)
          onPostRefund()
        }
      }, 1000)

      return
      // setCurParams(response.data)

      // IntervalMessage = setInterval(async () => {
      //   await checkRefundStatus(params)
      // }, 1000)
      // setTimeout(() => {
      //   clearInterval(IntervalMessage)
      // }, 5000)
    } else {
      message.error("Generate QR Code Failed. Please Check The Network.")
    }
  }

  const callTransactionApi = async () => {
    let customerCopy: string[] = []//initial for concat
    const purchaseRequest = {
      txtPurchaseAmount: refundTotal.totalAmount,
      txtCashOutAmount: 0,
      applicationCode: 0,
      requestOptionCode: 1,
    }
    await doPurchase(purchaseRequest)
    // Try getting the customer copy receipt
    try {
      await getReceipt()
    } catch (error) {
      console.error("getReceipt failed", error)
      // continue
    }
    const messageInterval = setInterval(async () => {
      const message = await getMessage()
      // //customer copy = merchant copy
      //每次收到最新的receipt消息都处理并取代旧的customerCopy
      if (message.type === "Receipt" && message.content.indexOf("APPROVED") > -1) {//customer copy = merchant copy
        // if (!customerCopySaved) {//该modal处于隐藏状态，该变量不会重置
          const content = JSON.parse(message.content)
          console.log("客户拷贝是。。。:", content)
          const receiptText: string[] = content.receiptText
          if (receiptText.length > 0) {
            customerCopy = [
              "========================",
              "     CUSTOMER COPY      ",
            ]
            customerCopy = customerCopy.concat(receiptText.slice(9, 25))
            customerCopy.push("========================")
            // setReceiptProcessed(true)
            // dispatch(setReceiptCopy({ customerCopy: customerCopy }))
          }
          // customerCopySaved = true
        // }
      }
      //------------end of customer copy process----------------

      if (message.type === "Transaction") {
        const content = JSON.parse(message.content)

        // Add a submitting state check to avoid duplicated submission
        if (content.success && !isSubmitting.current) {
          isSubmitting.current = true
          clearInterval(messageInterval)
          console.log("Customer copy for refund:", customerCopy)

          try {
            await onPostRefund(customerCopy)
          } catch (error) {
            console.error("Error occurred when posting refund to backend", error)
          } finally {
            isSubmitting.current = false
          }
        } else {
          clearInterval(messageInterval)
          console.error("Refund failed:", message.content)
          notification.error({
            message: "Refund Failed",
            description: `Message:\n========================\n${message.content}`,
            style: { whiteSpace: "pre" },
            duration: 10,
          })
        }
      }
    }, 100)
  }

  const onPostRefund = async (customerCopy?: string[]) => {
    const amount = -refundTotal.totalAmount
    const netAmount = -refundTotal.netAmount
    const items = getRefundInvoiceItems()
    const ReferenceNo = currentRefundInvoice.InvoiceNo
    const RecvAcct = {
      //@ts-ignore
      PayBy: selectedRefundMethod ? selectedRefundMethod : "CASH",
      PaidAmount: amount,
      MachineID,
      Transfer: false,
      PaymentID: randomInteger(1, 10000),
    }
    //启动时间区间二倍积分，不区分是否扫条码
    let isDoublePoints: number = 0
    const curDate: string = moment().format("YYYY-MM-DD")
    if (curDate >= "2024-02-09" && curDate <= "2024-02-11") {
      console.log("new当前时间在范围内：", curDate)
      isDoublePoints = 1
    }
    const payload = {
      InvoiceHead: {
        Amount: Number(netAmount.toFixed(2)),
        PaidAmount: Number(amount.toFixed(2)),
        CustomerCode: currentRefundInvoice.CustomerCode,
        InvoiceDate: new Date().toString(),
        InvoiceItems: items,
        WarehouseCode,
        ReferenceNo,
        MachineID,
        CurrentPoints: servingCustomer ? servingCustomer.Points : 0,//add customer points before this transaction
        ReceiptCustomerCopy: customerCopy && customerCopy.length > 0 ? customerCopy.toString() : null,
      },
      //如果扫客户码，二倍积分, 不区分是否扫码
      RewardPoints: servingCustomer && isDoublePoints ? Math.ceil(amount * 2) : Math.ceil(amount * 1),
      // RewardPoints: servingCustomer && servingCustomer.isScan ? Math.ceil(amount * 2) : Math.ceil(amount * 1),
      // RewardPoints: Math.ceil(amount * 1),
      //@ts-ignore
      RecvAcct,
      OpFirebaseUid: CurUser.uid,
    }
    console.log("待提交的refund发票信息", payload)
    //@ts-ignore
    await postRefund(payload, postRefundCallback)
  }

  const onManagerLogin = async (values: any) => {
    // TODO: verify manager id
    const result: any | undefined = await loginUser(values.username, values.password, values.barcode)
    console.log("manager approve login result: ", result, CurUser)
    if (result?.user && result.user.RefundApprove) {//判断是否有refund的授权
      // if (result?.user) {
      //@ts-ignore
      setIsLoggedIn(true)
    } else {
      notification.error({
        message: "Manager Barcode Verify Failed Or Non-Authorized",
        duration: 10,
      })
      setIsLoggedIn(false)
    }
  }

  const onChangeMode = (e: any) => {
    setIsScanMode(e.target.value)
  }

  const onApproval = () => {
    if (selectedPaymentType === BackendPaymentMethod.EFTPOS) {
      callTransactionApi()
    } else if (selectedPaymentType === BackendPaymentMethod.RoyalPay) {
      //todo 发起royalpay的refund
      callRoyalPayApi()
    } else {
      onPostRefund()
    }
  }

  const onDeny = () => {
    setVisibility(false)
  }

  const ManagerLogin = () => (
    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
      <KeyboardModal
        ref={keyboardRef}
        isVisible={isKeyboardVisible}
        language={keyboardLanguage}
        onHideKeyboard={() => {
          setIsKeyboardVisible(false)
        }}
        onSwitchLanguage={() => {
          if (keyboardLanguage === "eng") {
            setKeyboardLanguage("kor")
          } else {
            setKeyboardLanguage("eng")
          }
        }}
        onConfirm={(value: string) => {
          console.log("keyboard modal confirm...", value)
          // if (value && value !== '000001') {
          //   setSearchCustomerValue(value)
          //   searchCustomer(value)
          // } else if (value === '000001') {
          //   setErrorMsg("You can not redeem points with Cash Customer!")
          //   setIsAlertModalVisible(true)
          // }
          setIsKeyboardVisible(false)
        }}
        onSelect={(value) => {
          console.log("keyboard modal select...", inputRef.current, value)
          form.setFieldsValue({ [inputRef.current]: value })
          // inputRef.current === "password"? setPasswordValue(value) : setUsernameValue(value)
        }}
        // mask={false}
        // initialValue={form.getFieldValue(inputRef.current ?? "uesername")}
      />
      <div style={{ marginBottom: 50, display: "flex", flexDirection: "row" }}>
        <Typography style={{ fontWeight: "bold", marginRight: 10 }}>Login Method: </Typography>
        <Radio.Group onChange={onChangeMode} value={isScanMode}>
          <Radio value={true}>ID Card</Radio>
          <Radio value={false}>password</Radio>
        </Radio.Group>
      </div>
      {
        isScanMode ?
          <Typography style={{ fontSize: 16, fontWeight: "bold" }}>Please scan your ID</Typography>
          :
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            // initialValues={{ remember: true, username: "admin@aibuild.com", password: "password" }}
            onFinish={onManagerLogin}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: "Please input your username!" }]}
            >
              <Input
                onClick={() => {
                  inputRef.current = "username"
                  console.log("显示keyboard属性", keyboardRef)
                  // keyboardRef.current.setInput(form.getFieldValue(inputRef.current ?? "uesername"))
                  setIsKeyboardVisible(true)
                }}
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "Please input your password!" }]}
            >
              <Input.Password
                onClick={() => {
                  inputRef.current = "password"
                  setIsKeyboardVisible(true)
                }}
              />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
      }
    </div>
  )

  const RefundInfo = () => (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ marginBottom: 20, width: "500px" }}>
        <div className="detailDescriptionsSmall">
          <div className="detailDescLabelSmall">
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/customer-icon@2x.png"} className="pageIcon16" />Customer:
          </div>
          <div className="detailDescValueSmall" style={{ fontWeight: "bold" }}>
            {currentRefundInvoice?.Customer?.CustomerName}
          </div>
        </div>
        <div className="detailDescriptionsSmall">
          <div className="detailDescLabelSmall">
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/sales-icon@2x.png"} className="pageIcon16" />Invoice
            Date:
          </div>
          <div className="detailDescValueSmall" style={{ fontWeight: "bold" }}>
            {moment(currentRefundInvoice?.InvoiceDate).format(dateFormat)}
          </div>
        </div>
        <div className="detailDescriptionsSmall">
          <div className="detailDescLabelSmall">
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/store-icon@2x.png"} className="pageIcon16" />Buy
            From:
          </div>
          <div className="detailDescValueSmall" style={{ fontWeight: "bold" }}>
            {currentRefundInvoice.WarehouseCode ? invoiceShopName : "unkown shop"}
          </div>
        </div>
        <div className="detailDescriptionsSmall">
          <div className="detailDescLabelSmall">
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/referenceNo-icon@2x.png"} className="pageIcon16" />Payment
            Type:
          </div>
          <div className="detailDescValueSmall" style={{ fontWeight: "bold" }}>
            {selectedPaymentType}
          </div>
        </div>
        {/* <div style={{ display: "flex", flexDirection: "row" }}>
          <Typography style={{ fontWeight: "bold", fontSize: 16, marginRight: 10 }}>Customer:</Typography>
          <Typography style={{ fontSize: 16 }}>{currentRefundInvoice?.Customer?.CustomerName}</Typography>
        </div> */}

        {/* <div style={{ display: "flex", flexDirection: "row" }}>
          <Typography style={{ fontWeight: "bold", fontSize: 16, marginRight: 10 }}>Invoice Date:</Typography>
          <Typography style={{ fontSize: 16 }}>{moment(currentRefundInvoice?.InvoiceDate).format(dateFormat)}</Typography>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <Typography style={{ fontWeight: "bold", fontSize: 16, marginRight: 10 }}>Buy From:</Typography>
          <Typography style={{ fontSize: 16 }}>{"City Shop 1"}</Typography>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <Typography style={{ fontWeight: "bold", fontSize: 16, marginRight: 10 }}>Payment Type:</Typography>
          <Typography style={{ fontSize: 16 }}>{selectedPaymentType}</Typography>
        </div> */}

      </div>
      <div style={{ marginBottom: 20 }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography style={{ fontWeight: "bold", fontSize: 16 }}>Refund Items:</Typography>
          <Typography style={{ fontSize: 18, fontWeight: "bold", color: "red" }}>Total:
            -${refundTotal?.totalAmount?.toFixed(2)}</Typography>
        </div>
        <Table
          className="pageDetailTable"
          columns={itemColumns}
          dataSource={refundItems}
          pagination={false}
          rowClassName={(_, index) =>
            (index % 2 > 0) ? "rowStyle1" : "rowStyle2"
          }
          // style={{ height }}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <button
          style={{
            height: "50px",
            width: "300px",
            border: "none",
            borderRadius: "5px",
            backgroundColor: "#D4F5E5",
            color: "#32B373",
            fontWeight: "normal",
            fontSize: "18px",
          }}
          onClick={onApproval}
        >
          Approve
        </button>
        {/* <Button
          type="primary"
          style={{ width: 120, height: 50 }}
          onClick={onApproval}
        >
          Approve
        </Button> */}
        <button
          style={{
            height: "50px",
            width: "300px",
            border: "none",
            borderRadius: "5px",
            backgroundColor: "#F8E0E0",
            color: "#E63737",
            fontWeight: "normal",
            fontSize: "18px",
          }}
          onClick={onDeny}
        >
          Deny
        </button>
        {/* <Button
          type="primary"
          danger
          style={{ width: 120, height: 50 }}
          onClick={onDeny}
        >
          Deny
        </Button> */}
      </div>
    </div>
  )

  const handleScan = (value: string) => {
    if (!value || value === "" || !isVisible) {
      console.log("扫manager码不满足条件")
      return
    }
    console.log("manager码扫到：", value)
    // todo 调用员工码识别api
    onManagerLogin({ barcode: value })
    // setSearchCustomerValue(value)
    // searchCustomer(value)
  }

  const scannerCallback = useCallback((event) => {
    let data = buffer || ""
    if (event.key === "") {
      return
    }
    if (event.key !== "Enter") { // barcode ends with enter -key
      data += event.key
      buffer = data
    } else {
      buffer = ""
      if (isVisible) {
        handleScan(data)
      }
    }
  }, [])

  useEffect(() => {
    console.log("currentRefundInvoice", currentRefundInvoice)
    getWarehouseName(currentRefundInvoice.WarehouseCode).then(
      (res) => setInvoiceShopName(res),
    )
    document.addEventListener("keypress", scannerCallback, true)
    return () => {
      clearInterval(IntervalMessage)
      document.removeEventListener("keypress", scannerCallback, true)
    }
  }, [])

  useEffect(() => {
    if (Globals.barcode) {
      handleScan(Globals.barcode)
      dispatch(freshBarcode({
        barcode: "",
      }))
    }
  }, [Globals.barcode])

  return (
    <Modal
      transitionName="none"
      maskTransitionName="none"
      className="modalPage"
      title={<div className="modalTitle">Refund Waiting for Approval
        <div style={{ width: "40px", marginTop: "-10px" }}><Divider className="modalDivider" /></div>
      </div>}
      visible={isVisible}
      footer={null}
      onCancel={(e) => {
        setVisibility(false)
      }}
      width={800}
    >
      {responseMsg?.type ?
        <Tag color={responseMsg?.type === "success" ? "success" : "error"}
             style={{ fontSize: "18px", marginLeft: "10px" }}>
          {responseMsg?.message}
        </Tag>
        :
        null
      }
      {
        isLoggedIn ?
          <RefundInfo />
          :
          <ManagerLogin />
      }
    </Modal>
  )
}
