import API, { pageSize } from "../constants/API"
import { generateRequestUrl } from "../utils/Global" 
import { notification } from "antd"
import axios from "axios"

const purchaseUrl = "http://localhost:8080/eftpos/purchase"
const messageUrl = "http://localhost:8080/eftpos/message"
const pressokUrl = "http://localhost:8080/eftpos/pressok"
const pressyesUrl = "http://localhost:8080/eftpos/pressyes"
const pressnoUrl = "http://localhost:8080/eftpos/pressno"
const receiptUrl = "http://localhost:8080/eftpos/receipt"
const logOnUrl = "http://localhost:8080/eftpos/logon"
const lastUrl = "http://localhost:8080/eftpos/getlast"
const rePrintUrl = "http://localhost:8080/eftpos/reprint"

export const doPurchase = async (purchaseRequest: any) => {
  try {
    const response = await axios.post(purchaseUrl, purchaseRequest)
    console.log("doPurchase response: ", response)
  } catch (e) {
    console.log("doPurchase error", e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}

export const getMessage = async () => {
  try {
    const response = await axios.get(messageUrl)
    console.log("getMessage response: ", response)
    return response.data
  } catch (e) {
    console.log("getMessage error", e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}
//eftpos终端获取上次交易状态
export const getLast = async () => {
  try {
    const response = await axios.post(lastUrl)
    console.log("getMessage response: ", response)
  } catch (e) {
    console.log("getLast error", e)
    notification.error({
      message: "Something went wrong at getLast!"
    })
    return e
  }
}
export const rePrint = async () => {
  try {
    const response = await axios.post(rePrintUrl)
    console.log("getMessage response: ", response)
  } catch (e) {
    console.log("reprint error", e)
    notification.error({
      message: "Something went wrong at reprint!"
    })
    return e
  }
}

//eftpos终端登录
export const doLogon = async () => {
  try {
    const response = await axios.post(logOnUrl)
    console.log("getMessage response: ", response)
  } catch (e) {
    console.log("logon error", e)
    notification.error({
      message: "Something went wrong at logOn!"
    })
    return e
  }
}

export const clearMessage = async (reason?: string) => {
  try {
    const response = await axios.post(messageUrl)
    console.log("clearMessage response: ", response, "and the reason is-> ", reason)
    return response.data
  } catch (e) {
    console.log("clearMessage error", e)
    notification.error({
      message: "Something went wrong within clear message!"
    })
    return e
  }
}
/*
| EFTPOSKey | Description          |
| --------- | -------------------- |
| OKCancel  | CANCEL key or OK key |
| YesAccept | YES key              |
| NoDecline | NO key               |
| Authorise | AUTH key             |
*/
export const sendOK = async () => {
  try {
    const response = await axios.post(pressokUrl)
    console.log("sendOK response: ", response)
    return response.data
  } catch (e) {
    console.log("sendOK error", e)
    notification.error({
      message: "Something went wrong within send OK!"
    })
    return e
  }
}
export const sendYes = async () => {
  try {
    const response = await axios.post(pressyesUrl)
    console.log("sendYes response: ", response)
    return response.data
  } catch (e) {
    console.log("sendYes error", e)
    notification.error({
      message: "Something went wrong within send Yes!"
    })
    return e
  }
}
export const sendNo = async () => {
  try {
    const response = await axios.post(pressnoUrl)
    console.log("sendNo response: ", response)
    return response.data
  } catch (e) {
    console.log("sendNo error", e)
    notification.error({
      message: "Something went wrong within send No!"
    })
    return e
  }
}

export const getReceipt = async () => {
  try {
    const response = await axios.get(receiptUrl)
    console.log("response: ", response)
    return response.data
  } catch (e) {
    console.log("getReceipt error", e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}
